import {NavigationService} from './navigation.service';
import {ResolveTypes} from '@uirouter/angular';

export function resolveSetup(navigation: NavigationService): void {
  navigation.setPreviousPage();
}

export const navigationResolver: ResolveTypes = {
  token: '_',
  deps: [NavigationService],
  resolveFn: resolveSetup,
};
