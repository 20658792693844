import {Directive, HostListener} from '@angular/core';
import {LAST_VISITED_ROUTE} from '../constants';
import {PlatformLocation} from '@angular/common';

// The historyBackKeeper directive is used as a hostDirective in components on pages that have leave or stay functionality.
// To return the previous state of the history when the user tries to leave the page, but clicks on the "stay" button.
@Directive({
  selector: '[msHistoryBackKeeper]',
  standalone: true,
})
export class HistoryBackKeeperDirective {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    const lastVisitedRoute = localStorage.getItem(LAST_VISITED_ROUTE);

    if (!lastVisitedRoute) {
      this.location.pushState(this.location.getState(), '', this.location.href);
    }

    localStorage.setItem(LAST_VISITED_ROUTE, this.location.hash);
  }

  constructor(private readonly location: PlatformLocation) {}
}
