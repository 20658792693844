import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {Store} from '@ngxs/store';
import {NavigateBack, RouteParams, RouterState} from 'ngxs-ui-router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly NAVIGATION_KEY = 'navigation';

  constructor(
    private readonly storage: SessionStorageService,
    private readonly store: Store
  ) {}

  get previousPage(): {state: string; params?: RouteParams} {
    return this.storage.retrieve(this.NAVIGATION_KEY);
  }

  clear(): void {
    this.storage.clear(this.NAVIGATION_KEY);
  }

  setPreviousPage(): void {
    const state = this.store.selectSnapshot(RouterState.routeName);
    const params = this.store.selectSnapshot(RouterState.params);
    if (state) {
      this.storage.store(this.NAVIGATION_KEY, {state, params});
    }
  }

  openPreviousPage(): void {
    this.store.dispatch(new NavigateBack());
  }
}
